
import { defineComponent, ref, onMounted, reactive, computed } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { useStore } from "vuex";
import * as moment from "moment";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import BordPDF from "@/views/still/bord/pdf.vue"; 
import { ElNotification } from 'element-plus'
import mAxiosApi from "@/api";
import { useI18n } from "vue-i18n";
import { formatDateLang } from "@/App.vue";

export default defineComponent({
  name: "Overview",
  components: {
    Datatable,
    SearchAndFilter,
    BordPDF
  },
  setup() {
    const { t } = useI18n()
    const store = useStore();
    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const bordType = computed(() => {

      if (store.getters.bordType == "versement") return t('de versement')
      return t("d'élimination")
      
    });




    //moment.default.locale("fr");

    const currentBord = parseInt(localStorage.getItem("currentBord") || "0");

    const state = reactive({
      bor_n_seq : currentBord,
      bordereau : {} as any,
      loaderEnabled: true,
      myList: [] as any,
      drawer: ref(false),
      initialMyList: [],
      loadingDatatable: 0,
      myListLiv: [],
      lCateg: [] as any,

    });
    

    const form = reactive({
      art_n_seq: 0,
      art_n_num_ordre: "0",
      art_c_libelle : '',
      art_n_annee_mini : '',
      art_n_annee_maxi : '',
      art_x_sans_date : false,
      art_c_observations: '',
      art_knum_bordereau: 0,
      art_c_categorie: '',
      art_n_dua: '',
      art_c_ref_reglemantaire: '',
      art_metrage_lineaire: '',
    })



    const tableHeader = ref([
      { name: "N° d'ordre", key: "art_n_num_ordre", sortable: false, className:"w-100px"},
      { name: "Titre / Libellé", key: "art_c_categorie", sortable: false,},
      { name: "Dates", key: "art_n_annee_mini", sortable: false, className:"hide-small"},
      { name: "DUA", key: "art_n_dua", sortable: false, className:"hide-small"},
      { name: "Observations", key: "art_c_observations", sortable: false, className:"hide-small"},
      { name: "", key: "art_n_seq", sortable: false, className:"w-125px"},

    ]);

    const tableFilters = ref([
      { name: "titre", key: "art_c_categorie", label: "art_c_categorie", },
    ]);
    const formatDate = (d) => {
      return moment.default(d).format(formatDateLang);
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };



    const newEditArticle = async (mArt, xCopy: number) => {
      //console.log("bordereau",state.bordereau.bor_n_dua);
      const art_n_seq = mArt.art_n_seq
      if (art_n_seq == "0") {
        const tmpList = await loadArticles(0);
        form.art_n_num_ordre = tmpList.length > 0 ? (parseInt(tmpList[tmpList.length - 1].art_n_num_ordre) + 1).toString(): "1";
        form.art_n_seq = art_n_seq;
        form.art_c_libelle = '';
        form.art_n_annee_mini = '';
        form.art_n_annee_maxi = '';
        form.art_x_sans_date = false;
        form.art_c_observations = '';
        form.art_knum_bordereau = currentBord;
        form.art_c_categorie = '';
        form.art_n_dua = state.bordereau.bor_n_dua;
        form.art_c_ref_reglemantaire = '';
        form.art_metrage_lineaire = '';
      } else {
        const myArtTmp = await getAxios("/getSVArticlesById/" + art_n_seq);
        const myArt = myArtTmp.results

        form.art_n_num_ordre = myArt.art_n_num_ordre;
        form.art_n_seq = myArt.art_n_seq;
        form.art_c_libelle = myArt.art_c_libelle;
        form.art_n_annee_mini = myArt.art_n_annee_mini;
        form.art_n_annee_maxi = myArt.art_n_annee_maxi;
        form.art_x_sans_date = myArt.art_x_sans_date == "1" ? true : false;
        form.art_c_observations = myArt.art_c_observations;
        form.art_knum_bordereau = myArt.art_knum_bordereau;
        form.art_c_categorie = myArt.art_c_categorie;
        form.art_n_dua = myArt.art_n_dua;
        form.art_c_ref_reglemantaire = myArt.art_c_ref_reglemantaire;
        form.art_metrage_lineaire = myArt.art_metrage_lineaire;

        if (xCopy == 1) {
          form.art_n_seq = 0;
          const tmpList = await loadArticles(0);
          form.art_n_num_ordre = tmpList.length > 0 ? (parseInt(tmpList[tmpList.length - 1].art_n_num_ordre) + 1).toString(): "1";
        }

      }
      
      const lCateg = await getAxios("/getSVArticlesByCateg/" + currentBord);
      state.lCateg = lCateg.results;

      state.drawer = true;
    };

    const deleteArticle = async (art_n_seq) => {
      form.art_n_seq = art_n_seq;
      mAxiosApi
        .post("/deleteArticle", form)
        .then( async() => {
          ElNotification({ title: t('Succès'), message: t("Article supprimé de la liste."), type: 'success', })
          await loadArticles(1);
          state.loadingDatatable = state.loadingDatatable + 1
          state.drawer = false;
        })
    };

    const addEditArticle = async () => {

      form.art_n_annee_mini = !form.art_x_sans_date ? form.art_n_annee_mini : "";
      form.art_n_annee_maxi = !form.art_x_sans_date ? form.art_n_annee_maxi : "";
      form.art_c_ref_reglemantaire = !form.art_c_ref_reglemantaire ? "" : form.art_c_ref_reglemantaire;


      if (!form.art_x_sans_date) {
        const globalRegex = new RegExp('^[12][0-9]{3}$', 'g');
        if (globalRegex.test(form.art_n_annee_mini) == false ) {
          ElNotification({ title: t("Erreur"), message: t("L'année début semble être incorrecte. Veuillez vérifier votre saisie, svp"), type: 'error', })
          return false;
        }
        const testFin = globalRegex.test(form.art_n_annee_maxi);
        if (globalRegex.test(form.art_n_annee_maxi) == false) {
          ElNotification({ title: t("Erreur"), message: t("L'année de fin semble être incorrecte. Veuillez vérifier votre saisie, svp"), type: 'error', })
          return false;
        }
        if (form.art_n_annee_maxi < form.art_n_annee_mini) {
          ElNotification({ title: t("Erreur"), message: t("L'année de fin ne peut être inférieur à l'année de début. Veuillez vérifier votre saisie, svp"), type: 'error', })
          return false;
        }
      }
      if(form.art_metrage_lineaire) {
        if(!(!isNaN(parseFloat(form.art_metrage_lineaire)) && isFinite(form.art_metrage_lineaire as any))) {
          ElNotification({ title: t("Erreur"), message: t("Le métrage linéaire doit être un nombre ou vide. Veuillez vérifier votre saisie, svp"), type: 'error', })
          return false;
        }
      }

      mAxiosApi
        .post("/addEditArticle", form)
        .then( async() => {
          state.loaderEnabled = true;
          const mNotif = form.art_n_seq > 0 ? t("Article modifié avec succès !") : t("Article créé avec succès !");
          ElNotification({ title: t("Succès"), message: mNotif, type: 'success', })
          await loadArticles(1);
          state.loadingDatatable = state.loadingDatatable + 1
          state.drawer = false;
          state.loaderEnabled = false;
        })
        
    };

    const loadArticles = async (xReload = 1) => {
      let myListLiv = {} as any;
      myListLiv = await getAxios("/getSVArticles/" + currentBord);
      if (xReload) {
        state.myList = myListLiv.results;
        state.initialMyList = state.myList;
        state.loadingDatatable = state.loadingDatatable + 2
      }
      return myListLiv.results

    }


    const csvExport = async () => {
      
      const mArray = [] as any;     
      state.initialMyList.forEach(element => {
        let myElem = JSON.stringify(element);
        
        myElem = myElem.replaceAll('\\n', " ");
        myElem = myElem.replaceAll('\\r', " ");
        myElem = myElem.replaceAll(";", '.');        
        mArray.push(JSON.parse(myElem));
      });

      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(mArray[0]).join(";"),
        ...mArray.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      
      const filename = "SV_Export_Bordereau_1324_" + moment.default().format("DD.MM.YYYY_H:mm:ss") + ".csv";

      link.setAttribute("download", filename);
      link.click();
    };


    onMounted(async () => {

      const myListLiv = await getAxios("/getSVoneBord/"+ currentBord);
      state.bordereau = myListLiv.results

      await loadArticles(1);
      state.loaderEnabled = false;

      //console.log("Initial !",state.initialMyList);

    });


    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      tableFilters,
      form,
      addEditArticle,
      newEditArticle,
      deleteArticle,
      csvExport,
      pageTitle,
      bordType
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
